<template>
  <div
    v-if="showPageHeader"
    class="page-header"
  >
    <b-container class="d-flex align-items-center">
      <i
        v-if="iconClass"
        class="mr-3"
        v-bind:class="iconClass"
      ></i>
      <h5 class="mb-0 font-weight-bold">
        {{title}}
      </h5>
      <div class="ml-5">
        <button class="btn btn-outline-primary" onclick="window.location.href='https://genialprev.curseduca.pro/m/lessons/como-usar-a-calculadora-1739825385969'">
          <span class="text-gray-500 hover-content">
            <i class="fa fa-question-circle mr-2"></i>
             Guia rápido para utilizar 
          </span>
        </button>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  computed: {
    showPageHeader() {
      return Boolean(this.meta?.title);
    },

    meta() {
      const route = this.$route.matched.find((r) => r.meta?.title);
      return route?.meta;
    },

    iconClass() {
      const { icon } = this.meta || {};
      if (!icon) { return; }
      return `fa ${icon}`;
    },

    title() {
      return this.meta?.title;
    },
  },
};
</script>

<style scoped>
  .hover-content:hover {
    color: #dee2e6 !important;
  }
  .page-header {
    font-weight: 600;
    padding: 0 40px;
    height: 55px;
    font-size: 24px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    background-color: #0a446d;
  }
  .btn.btn-outline-primary:hover {
    background-color: #0b6baf;
    border-color: #0b6baf;
  }
</style>
