<template>
  <div class="bg-primary text-center">
    <form class="form-signin" @submit.prevent="login">
      <img class="mb-5" src="../assets/logo.webp" alt="" >
      <h4 class="mb-3 text-white">Acesse sua conta</h4>

      <label for="email" class="sr-only">Email (login)</label>
      <input type="text" id="email" v-model="email" class="form-control mb-1" placeholder="Seu e-mail" required="" autofocus="">

      <label for="inputPassword" class="sr-only">Senha</label>
      <input type="password" id="inputPassword" v-model="password" class="form-control" placeholder="Sua senha" required="">
      <button class="btn btn-lg btn-secondary btn-block mt-3" type="submit">Entrar</button>
      <div class="mt-3 mb-3">
        <p>Não possui acesso? <router-link to="/plans" class="text-white">Cadastre-se agora</router-link></p>
      </div>

      <div class="mt-3 mb-3">
        <p><router-link to="/forgot-password" class="text-white">Esqueci minha senha</router-link></p>
      </div>

      <p class="mt-5 mb-3">
        <Copyright class="text-center" />
      </p>
    </form>
  </div>
</template>

<script>
import Copyright from '@/components/Copyright.vue';

export default {
  name: 'Login',
  data() {
    return {
      email: "",
      password: ""
    };
  },
  components: { Copyright },
  methods: {
    login: function() {
      const { email , password } = this;
      this.$store.dispatch('AUTHENTICATE', { email , password }).then(() => {
        this.$router.push('/');
      }).catch( (e) => {
        this.$toast.open({
          message: this.$store.getters.authErrorMessage,
          type: 'error',
          position: 'top-right'
        });

      });
    },
  }
}
</script>

<style>
.form-signin {
    width: 100%;
    max-width: 390px;
    padding: 15px;
    margin: auto;
}
</style>
