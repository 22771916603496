<template>
  <div class="bg-primary">
    <div class="container text-white">
      <div class="py-5 text-center">
        <img class="d-block mx-auto mb-4" src="../assets/logo.webp">
        <h2>Planos</h2>
      </div>

      <div class="text-center" v-if="loading">
        <b-spinner class="text-white" variant="primary" label="Spinning"></b-spinner>
        <h6 class="mb-0 mt-2 font-weight-bold">Carregando planos...</h6>
      </div>

      <div class="row" v-else>
        <div class="col-12 d-flex justify-content-center flex-nowrap align-items-end mb-4">
          <div class="d-flex flex-column" @click="changeTab('anual')">
            <span class="balloon" v-if="discountPercentage">Desconto de até {{ discountPercentage }}%</span>
            <button class="bg-custom-blue border-0 outline-none px-3 pb-2 text-unselected" :class="{ 'text-selected': tab === 'anual' }">Assinatura anual</button>
            <div :class="{ 'border-selected': tab === 'anual', 'border-unselected': tab !== 'anual' }"></div>
          </div>
          <div class="d-flex flex-column" @click="changeTab('mensal')">
            <button class="bg-custom-blue border-0 outline-none px-3 pb-2 pl-2 text-unselected" :class="{ 'text-selected': tab === 'mensal' }">Assinatura
              mensal</button>
            <div :class="{ 'border-selected': tab === 'mensal', 'border-unselected': tab !== 'mensal'  }"></div>
          </div>
        </div>

        <div class="container plans-header row">
            <div class="col-3 bg-light-blue d-flex flex-column px-4 py-2 text-white mobile-class">
              <p class="mt-4 mb-2 font-weight-bold text-12">Todos os planos incluem:</p>
              <div class="my-2"><span class="font-weight-bold text-uppercase text-12">Conteúdo Educacional</span>
                <p class="text-12">O conteúdo educacional formatado pela mentora abordará temas avançados relacionados ao RPPS (Regime Próprio de Previdência Social), com módulos estruturados para facilitar o aprendizado e o entendimento de conceitos complexos.</p>
              </div>
              <div class="my-2"><span class="font-weight-bold text-uppercase text-12">Plataforma de ensino on-line</span>
                <p class="text-12">A plataforma de ensino da Genial Prev, oferecendo vídeos, documentos de apoio e quizzes interativos.</p>
              </div>
              <div class="my-2"><span class="font-weight-bold text-uppercase text-12">Aulas ao vivo com a mentora</span>
                <p class="text-12">As sessões ao vivo serão realizadas via plataforma de videoconferência integrada ao sistema.</p>
              </div>
              <div class="my-2"><span class="font-weight-bold text-uppercase text-12">Grupo de WhatsApp</span>
                <p class="text-12">O grupo será exclusivo para alunos e mentora, com a finalidade de oferecer dicas e responder perguntas.</p>
              </div>
            </div>
            
            <div class="d-flex col-9 p-0 mobile-class flex-wrap" v-if="tab === 'anual'">
              <div 
                class="plan-card d-flex flex-column px-4 py-2 mobile-plan-card position-relative bg-white mobile-class" 
                :class="{ 'col-12': yearlyPlans.length === 1, 'col-6': yearlyPlans.length === 2, 'col-4': yearlyPlans.length === 3 }"
                v-for="plan of yearlyPlans" :key="plan.id"
              >
                <div class="d-flex flex-column">
                  <div>
                    <p class="text-16 font-weight-bold py-3 text-uppercase text-orange text-center">{{plan.name}}</p>
                  </div>
                  
                  <p class="unavailable-plan-badge" v-if="!plan.available">Indísponivel</p>

                  <!-- <p class="discounted-value">R$ 123,00</p> -->

                  <div :class="{ 'm-auto': yearlyPlans.length === 1 }">
                    <div v-if="plan.card && plan.card.discount && plan.available">
                      <p class="text-16 text-dark-blue font-weight-bold mb-0">
                        De
                      </p>
                      
                      <p class="text-24 text-dark-blue font-weight-bold pl-3 mb-0 discounted-value" v-if="plan.card.monthlyValue">
                        R$ <span class="">{{ +plan.card.monthlyValue.split('.')[0] + +plan.card.discount }}</span>,{{ plan.card.monthlyValue.split('.')[1] }}/mês
                      </p>

                      <p class="yearly-total-value-installments pl-3 discounted-value" v-if="plan.pix">Ou R$ {{ formatMoney(+plan.pix.value + +plan.pix.discount, 2) }} à vista no PIX </p>

                      <p class="text-16 text-dark-blue font-weight-bold mb-0">
                        Por
                      </p>

                      <p class="text-16 text-dark-blue font-weight-bold pl-3 mb-0" v-if="plan.card.monthlyValue">
                        R$ <span class="text-32">{{ plan.card.monthlyValue.split('.')[0] }}</span>,{{ plan.card.monthlyValue.split('.')[1] }}/mês
                      </p>

                      <p class="yearly-total-value-installments pl-3" v-if="plan.pix">Ou R$ {{ formatMoney(plan.pix.value, 2) }} à vista no PIX </p>
                    </div>
                    
                    <div v-else>
                      <p class="text-16 text-dark-blue font-weight-bold" v-if="plan.monthlyValue  && plan.available">
                        R$ <span class="text-32">{{ plan.monthlyValue.split('.')[0] }}</span>,{{ plan.monthlyValue.split('.')[1] }}/mês
                      </p>

                      <p class="yearly-total-value-installments" v-if="plan.pix && plan.available">Ou R$ <span>{{ formatMoney(plan.pix.value - plan.pix.discount, 2) }}</span> à vista no PIX </p>
                    </div>
                  </div>
                  
                  <div class="mt-4" :class="{ 'm-auto pl-5':yearlyPlans.length === 1 }">
                    <div class="plan-benefits mt-1" v-for="item of plan.items" :key="item">
                      <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 4.5L4.5 8L11 1.5" stroke="#363F5E" stroke-width="1.5"></path>
                      </svg>
                      <span class="text-12 text-light-gray">{{ item }}</span>
                    </div>
                  </div>
                  
                  <button 
                    class="new-buy-btn" 
                    :class="{ 'btn-ghost-dark-blue': plan.available, 'btn-ghost-dark-blue-unavailable': !plan.available }"
                    :disabled="!plan.available"
                    @click="register(plan)"
                  > 
                    {{ !plan.available ? 'Indísponivel' : 'Iniciar teste' }} 
                  </button>
                    
                  <span class="warranty-text">{{ plan.guarantee }} de garantia</span>
                </div>

                <div class="border-top mt-2 pt-2"></div>
              </div>
            </div>

            <div class="d-flex col-9 p-0 mobile-class flex-wrap" v-if="tab === 'mensal'">
              <div 
                class="plan-card d-flex flex-column px-4 py-2 mobile-plan-card position-relative bg-white mobile-class" 
                :class="{ 'col-12': monthlyPlans.length === 1, 'col-6': monthlyPlans.length === 2, 'col-4': monthlyPlans.length === 3 }"
                v-for="plan of monthlyPlans" :key="plan.id"
              >
                <div class="d-flex flex-column">
                  <div class="d-flex justify-content-between align-items-center">
                    <p class="text-16 font-weight-bold py-3 text-uppercase text-orange">{{plan.name}}</p>
                  </div>
                  
                  <p class="unavailable-plan-badge" v-if="!plan.available">Indísponivel</p>

                  <!-- <p class="discounted-value">R$ 123,00</p> -->

                  <p class="text-16 text-dark-blue font-weight-bold" v-if="plan.monthlyValue && plan.available">
                    <span>R$ </span><span class="text-32">{{ plan.monthlyValue.split('.')[0] }}</span><span>,{{ plan.monthlyValue.split('.')[1] }}</span><span>/mês</span>
                  </p>
                  
                  <!-- <p class="yearly-total-value-installments" v-if="plan.value  && plan.available">R$ <span>{{ plan.value }}</span> parcelado em 12x no cartão </p> -->

                  <div class="mt-4">
                    
                    <div class="plan-benefits mt-1" v-for="item of plan.items" :key="item">
                      <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 4.5L4.5 8L11 1.5" stroke="#363F5E" stroke-width="1.5"></path>
                      </svg>
                      <span class="text-12 text-light-gray">{{ item }}</span>
                    </div>
                  </div>
                  
                  <button 
                    class="new-buy-btn" 
                    :class="{ 'btn-ghost-dark-blue': plan.available, 'btn-ghost-dark-blue-unavailable': !plan.available }"
                    :disabled="!plan.available"
                    @click="register(plan)"
                  > 
                    {{ !plan.available ? 'Indísponivel' : 'Iniciar teste' }} 
                  </button>
                    
                  <span class="warranty-text">{{ plan.guarantee }} de garantia</span>
                </div>

                <div class="border-top mt-2 pt-2"></div>
              </div>
            </div>
        </div>
      </div>

      <div>
        <b-modal id="modal-1" title="TERMOS DE USO DOS SERVIÇOS ORIENTA LEGAL LTDA.">
          <TermsAndConditions />
        </b-modal>
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from 'components/Footer';
import TermsAndConditions from 'components/TermsAndConditions';
import { getPlans } from '../services/plans';
import { mapGetters } from 'vuex';
// import { monthlyPlans, yearlyPlans } from '../utils/plans';

export default {
  name: 'Plans',
  components: {
    Footer,
    TermsAndConditions,
  },
  data() {
    return {
      loading: false,
      tab: 'anual',
      plans: [],
      planOrder: {
        'Educacional': 1,
        'Gênio': 2,
        'Mestre': 3
      }
    };
  },
  created() {
    this.fetchPlans();
  },
  computed: {
    ...mapGetters(['authstatus', 'authErrorMessage']),

    discountPercentage() {
      const planWithPix = this.plans.find(plan => plan.pix);

      if (!planWithPix) return null;

      return planWithPix.pix.percentDiscount;
    },

    monthlyPlans() {
      return this.plans.filter(plan => plan.period === 'monthly').sort((a,b) => this.planOrder[a.name] - this.planOrder[b.name]) || [];
    },

    yearlyPlans() {
      return this.plans.filter(plan => plan.period === 'yearly').sort((a,b) => this.planOrder[a.name] - this.planOrder[b.name]) || [];
    }
  },
  methods: {
    async fetchPlans() {
      this.loading = true;

      const plans = await getPlans();

      const subscriptionPlans = plans.filter(p => p.type === "SUBSCRIPTION");
      const cardPlans = plans.filter(p => p.type === "CREDIT_CARD");
      const pixPlans = plans.filter(p => p.type === "PIX");

      for (const subscriptionPlan of subscriptionPlans) {
        const cardPlan = cardPlans.find(p => p.name === subscriptionPlan.name && p.period === subscriptionPlan.period);
        const pixPlan = pixPlans.find(p => p.name === subscriptionPlan.name && p.period === subscriptionPlan.period);

        subscriptionPlan.card = cardPlan;
        subscriptionPlan.pix = pixPlan;
      }

      const availablePlans = subscriptionPlans.filter(p => p.available);

      this.plans = availablePlans;

      this.loading = false;
    },
    changeTab(tab) {
      this.tab = tab;
    },
    register(plan) {
      this.$router.push({ name: 'Register', params: { plan }})
    },
    formatMoney(value, digits) {
      const d = digits !== undefined ? digits : 2;
      const nf = new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: d,
        maximumFractionDigits: d,
      });
      return nf.format(value);
    },
  },
}
</script>

<style>

@media screen and (max-width: 768px) {
  .mobile-class {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

.single-price {
  text-align: center;
  background: #ed7b02;
  transition: .7s;
  margin-top: 20px;
}

.single-price h3 {
  font-size: 25px;
  color: #000;
  font-weight: 600;
  text-align: center;
  margin: 0;
  margin-top: -80px;
  font-family: poppins;
  color: #fff;
}

.single-price h4 {
  font-size: 48px;
  font-weight: 500;
  color: #fff;
}

.single-price h4 span.sup {
  vertical-align: text-top;
  font-size: 25px;
}

.deal-top {
  position: relative;
  background: #262626 !important;
  font-size: 16px;
  text-transform: uppercase;
  padding: 136px 24px 0;
}

.deal-top::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -50px;
  width: 0;
  height: 0;
  border-top: 50px solid #262626;
  border-left: 175px solid transparent;
  border-right: 170px solid transparent;
}

.deal-bottom {
  padding: 56px 16px 0;
}

.deal-bottom ul {
  margin: 0;
  padding: 0;
}

.deal-bottom ul li {
  font-size: 16px;
  color: black;
  font-weight: 300;
  margin-top: 16px;
  border-top: 1px solid #E4E4E4;
  padding-top: 16px;
  list-style: none;
}

.btn-area a {
  display: inline-block;
  font-size: 18px;
  color: #fff;
  background: #ed7b02;
  padding: 8px 64px;
  margin-top: 32px;
  border-radius: 4px;
  margin-bottom: 40px;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
}


.single-price:hover {
  background: #ed7b02;
}

.single-price:hover .deal-top {
  background: #262626;
}

.single-price:hover .deal-top:after {
  border-top: 50px solid #262626;
}

.single-price:hover .btn-area a {
  background: #262626;
}

/* ignore the code below */
.link-area {
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 15px;
  border-radius: 40px;
  background: tomato;
}

.link-area a {
  text-decoration: none;
  color: #fff;
  font-size: 25px;
}

.text-selected {
  color: #ed7b02;
  font-weight: 700;
}

.text-unselected {
  font-weight: 700;
}

.border-selected {
  width: 100%;
  height: 3px;
  background-color: #3F87F5;
}

.border-unselected {
  width: 100%;
  height: 3px;
  background-color: rgb(108, 117, 125, 0.16);
}

.bg-custom-blue {
  background-color: #0b6baf;
}

.balloon {
    display: inline-block;
    background-color: #DCF5E6;
    color: #18A450;
    padding: 5px 10px;
    border-radius: 10px;
    position: relative;
    font-weight: 900;
    font-size: 12px;
    text-align: center;
    margin-bottom: 7px;
}

.bg-light-blue {
    background-color: #3F87F5;
}

.text-12 {
    font-size: .75rem !important;
}

.text-16 {
    font-size: 1em !important;
    line-height: 1.3 !important;
    font-weight: 400;
}

.text-24 {
  font-size: 1.5rem !important;
}

.text-32 {
    font-size: 32px;
    line-height: 40px;
}

.text-orange {
  color: #ed7b02;
}

.text-dark-blue {
    color: #363f5e;
}

.plan-card {
    border-left: 3px solid #D9D9D9;
}

.popular-plan-badge {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    background-color: #3F87F5;
    padding: 1px 5px;
}

.unavailable-plan-badge {
  color: #FFFFFF;
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    background-color: rgb(255, 60, 60);
    padding: 6px 32px;
}

.text-light-gray {
    color: #999999;
}

.discounted-value-monthly {
  width: 78%;
  position: relative;
  border-top: 2px solid red;
  top: 13%;
}

.discounted-value-pix {
  width: 78%;
  position: relative;
  border-top: 2px solid red;
  top: 5%;
}

.discounted-value {
    color: #999999;
    text-decoration: line-through red;
    font-weight: 700;
}

.yearly-total-value-installments {
    color: #999999;
    font-size: 11px;
    font-weight: 600;
}

.plan-benefits {
    display: flex;
    align-items: center;
    column-gap: 0.7rem;
}

.btn-ghost-dark-blue {
    background-color: #363F5E;
    border: 3px solid #363F5E;
    border-radius: 4px;
    text-transform: uppercase;
    color: #FFF;
    font-weight: 800;
    padding: 10px 20px;
    margin-top: 30px;
}

.btn-ghost-dark-blue-unavailable {
    background-color: white;
    border: 3px solid #363F5E;
    border-radius: 4px;
    text-transform: uppercase;
    color: #363F5E;
    font-weight: 800;
    padding: 10px 20px;
    margin-top: 30px;
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.warranty-text {
    color: #18A450;
    font-size: 11px;
    font-weight: 700;
    text-align: center;
    margin-top: .25rem;
}

.clients-count-select {
    border: white;
    background-color: white;
    color: #3B424D;
    font-weight: 500;
}

.text-red {
  color: red;
}

</style>
