<template>
  <b-modal
    v-model="modalShow"
    @hidden="$emit('hidden')"
    size="xl"
  >
    <template #modal-header="{ close }">
      <div class="d-flex align-items-center flex-fill">
        <h5 class="mb-0">Importação CNIS</h5>

        <b-button class="ml-auto" size="sm" variant="link" @click="close()">
          <i class="fa fa-times" aria-hidden="true"></i> 
        </b-button>
      </div>
    </template>

    <template #default>
      <div class="row">
        <div class="col-12 mb-3">
          <input type="file" @change="onChangeFile" accept=".pdf" class="form-control" />
        </div>
        <div class="col-12 mb-3">
          <b-checkbox v-model="addMissingMonths">Adicionar salários para remunerações ausentes</b-checkbox>
        </div>
        <div class="col-12">
          <b-button @click="importCnis" variant="primary">Extrair Dados</b-button>
        </div>
      </div>
      <div class="mt-4">
        <div v-if="registers.length > 0">
          <div v-for="register in registers" :key="register.seq" class="mb-4">
            <div 
            class="d-flex align-items-center mb-3 cursor-pointer"
            @click="toggleRegister(register)"
          >
            <i 
              class="fas mr-2" 
              :class="register.isOpen ? 'fa-chevron-down' : 'fa-chevron-right'"
            ></i>
            <h5 class="mb-0">{{ register.seq }} ({{ register.initialDate || "Data inicial não definida" }} - {{ register.finalDate || register.lastRemuneration && `Última Remuneração: ${register.lastRemuneration}` || "Data final não definida" }}) {{ hasWarning(register.salaries) && '[Aviso]' }}</h5>
          </div>
          
          <table v-if="register.isOpen" class="table table-bordered">
            <thead>
              <tr>
                <th>Data</th>
                <th>Valor</th>
                <th>Valor Atualizado</th>
                <th>Avisos</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="salary in register.salaries" :key="salary.yearMonth">
                <td>{{ salary.yearMonth }}</td>
                <td>{{ salary.value }}</td>
                <td>{{ salary.updatedValue }}</td>
                <td>
                  <div v-for="warning in salary.warnings" :key="warning">
                    {{ warning }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>
      </div>
      <!-- <div v-else class="p-4 text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
        <h6 class="mb-0 mt-2 font-weight-bold text-gray-600">Carregando informações...</h6>
      </div> -->
    </template>

    <template #modal-footer="{ cancel }">
      <b-button
        v-on:click="save"
        variant="primary"
      >
        <b-spinner
          v-if="isSaving"
          small
          variant="white"
        ></b-spinner>
        Importar Dados
      </b-button>

      <b-button variant="outline-primary" @click="cancel()">
        Fechar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { BIcon } from 'bootstrap-vue'
import InputDatePicker from '@/components/InputDatePicker.vue';
import VueMonthlyPicker from 'vue-monthly-picker'
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import "pdfjs-dist/build/pdf.worker.entry";
import axios from 'axios';
import moment from 'moment';
export default {
  name: 'ModalImportCnisForm',
  components: { BIcon, InputDatePicker, VueMonthlyPicker },
  props: {
    updateIndex: {
      type: Array,
      required: true,
    },
    parentId: {
      type: String,
    },
  },

  created() {

  },

  data: () => ({
    cnisFile: null,
    registers: [],
    modalShow: true,
    isLoading: false,
    isSaving: false,
    addMissingMonths: false,
  }),

  computed: {

  },

  methods: {
    hasWarning(salaries) {
      return salaries.find(salary => salary.warnings.length > 0);
    },

    async save() {
      this.isSaving = true;

      let workRecords = [];
      this.registers.forEach(register => {
        let salaries = register.salaries.map(salary => {
          const salaryDate = `01/${salary.yearMonth}`;
          if (moment(salaryDate).isBefore('1994-07-01')) {
            return null;
          }

          const month = salary.yearMonth.split('/')[0];
          const year = salary.yearMonth.split('/')[1];
          const value = parseFloat(salary.value.replace('R$', '').trim().replace('.', '').replace(',', '.'));
          const updateIndex = this.updateIndex.find(index => index.month === month && index.year === year)?.index || 1;
          return {
            month: month,
            year: year,
            value: value,
            update: updateIndex,
            updatedValue: value * updateIndex
          };
        });

        salaries = salaries.filter(salary => salary);

        workRecords.push({
          companyName: register.seq,
          egressDate: register.finalDate || register.lastRemuneration || register.initialDate,
          ingressDate: register.initialDate,
          calculationId: this.parentId,
          salaries: salaries
        });
      });

      try {
        await axios.post('/work-records/multiple', { workRecords });
      } catch (err) {
        this.$toast.open({
          message: 'Erro ao importar',
          type: 'error',
          position: 'top-right'
        });
      }

      this.isSaving = false;

      this.$emit('save');
      this.$emit('hidden');
    },
    onChangeFile(event) {
      this.cnisFile = event.target.files[0];
    },
    importCnis() {
      if (this.cnisFile) {
        this.getTextFromFile(this.cnisFile);
      }
    },
    getTextFromFile(file) {
      let reader = new FileReader();
      reader.onload = () => {
        let fileContent = new Uint8Array(reader.result);
        fileToPdf(fileContent).then((pdf) => {
          pdfToText(pdf).then((text) => {
            this.registers = parseImportedSalaries(text, this.updateIndex, this.addMissingMonths);
          });
        });
      };
      reader.readAsArrayBuffer(file);
    },
    toggleRegister(register) {
      register.isOpen = !register.isOpen;
    }
  },
}

function groupByYearMonth(input) {
  return Object.values(input.reduce((grouped, { yearMonth, value, warnings = [] }) => {
    const numericValue = parseFloat(value.replace(".", "").replace(",", "."));
        
    if (!grouped[yearMonth]) {
      grouped[yearMonth] = { yearMonth, value: 0, values: [], warnings: [] };
    }
        
    grouped[yearMonth].value += numericValue;
    grouped[yearMonth].values.push(`R$ ${value}`);
    grouped[yearMonth].warnings.push(...warnings);
        
    return grouped;
  }, {})).map(({ yearMonth, value, values, warnings }) => {
    const formattedValue = `R$ ${value.toFixed(2).replace(".", ",")}`;
    if (values.length > 1) {
      warnings.push(`Valor atualizado para ${formattedValue}. Motivo: ${values.length} salários com mesma data. Valores originais: ${values.join(", ")}.`);
    }
    return { yearMonth, value: formattedValue, warnings };
  });
}

function parseImportedSalaries(importedText, updateIndexes = [], addMissingMonths = false) {
  const seqRegex = /Seq\.[\s\S]*?(?=Seq\.|$)/g;
  const dateRegex = /\b(?<date>\d{2}\/\d{2}\/\d{4})(?<time>\s+\d{2}:\d{2}:\d{2})?\b/g;
  const salaryRegex = /(?<yearMonth>\d{2}\/\d{4})\s+(?:R\$\s)?(?<value>(?:\d{1,3}\.?)+,\d{2})/g;
    
  let registers = []
  let seqIndex = 1;
  let seqMatch;
    
  while ((seqMatch = seqRegex.exec(importedText)) !== null) {
    const seqText = seqMatch[0];
    const dateCandidates = [...seqText.matchAll(dateRegex)].filter(m => !m.groups.time).map(m => m.groups.date);
    const salaries = [...seqText.matchAll(salaryRegex)].map(m => ({ yearMonth: m.groups.yearMonth, value: m.groups.value, warnings: [] }));
        
    let initialDate = dateCandidates[0] || "";
    let finalDate = dateCandidates[1] || "";
    let lastRemuneration = salaries.length ? `01/${salaries[salaries.length - 1].yearMonth}` : "";

    if (finalDate && moment(finalDate, 'DD/MM/YYYY').isBefore(moment(initialDate, 'DD/MM/YYYY'))) {
      finalDate = null;
    }

    if (initialDate && finalDate && addMissingMonths) {
      for (let i = moment(initialDate, 'DD/MM/YYYY').startOf('month'); i.isBefore(moment(finalDate, 'DD/MM/YYYY').endOf('month')); i.add(1, 'month')) {
        if (!salaries.find(salary => salary.yearMonth === i.format('MM/YYYY'))) {
          salaries.push({ yearMonth: i.format('MM/YYYY'), value: '0,00', warnings: ['Remuneração ausente.'] });
        }
      }
    }

    salaries.forEach(salary => {
      const salaryDate = `01/${salary.yearMonth}`;
      if (moment(salaryDate).isBefore('1994-07-01')) {
          salary.warnings.push('Salário será desconsiderado, pois foi anterior a 01/07/1994.');
      }
    });
    
    let groupedSalaries = groupByYearMonth(salaries);

    groupedSalaries = groupedSalaries.map(salary => {
      const [month, year] = salary.yearMonth.split('/');
      const updateInfo = updateIndexes.find(index => index.month === month && index.year === year) || {};
      
      let salaryValue = parseFloat(salary.value.replace('R$', '').replace('.', '').replace(',', '.'));

      if (updateInfo.min_salary && salaryValue < updateInfo.min_salary) {
        salary.warnings.push(`Valor atualizado para R$ ${updateInfo.min_salary.toFixed(2).replace('.', ',')}. Motivo: Valor menor que salário mínimo. Valor original: ${salary.value}.`);
        salary.value = `R$ ${updateInfo.min_salary.toFixed(2).replace('.', ',')}`;
        salaryValue = updateInfo.min_salary;
      }

      if (updateInfo.max_salary && salaryValue > updateInfo.max_salary) {
        salary.warnings.push(`Valor atualizado para R$ ${updateInfo.max_salary.toFixed(2).replace('.', ',')}. Motivo: Valor maior que teto salarial. Valor original: ${salary.value}.`);
        salary.value = `R$ ${updateInfo.max_salary.toFixed(2).replace('.', ',')}`;
        salaryValue = updateInfo.max_salary;
      }

      const updatedValue = salaryValue * (updateInfo.index || 1);
      return { ...salary, update: updateInfo.index || 1, updatedValue: `R$ ${updatedValue.toFixed(2).replace(".", ",")}` };
    });
        
    registers.push({ seq: `Seq. ${seqIndex++}`, initialDate, finalDate, lastRemuneration, salaries: groupedSalaries, isOpen: false });
  }

  return registers;
}

function itemsToString(items) {
  return items.map(item => item.str).join("\n");
}

function fileToPdf(file) {
  return new Promise((resolve, reject) => {
    if (!pdfjsLib) {
      reject(new Error("pdfjsLib is undefined"));
      return;
    }

    pdfjsLib.GlobalWorkerOptions.workerSrc = "//mozilla.github.io/pdf.js/build/pdf.worker.mjs";

    pdfjsLib.getDocument(file).promise
      .then((pdf) => resolve(pdf))
      .catch((error) => reject(error));
  });
}

function pdfToText(pdf, pageNumber = 1) {
  return new Promise((resolve) => {
      if (pageNumber > pdf.numPages) {
          resolve("");
      } else {
          pdfToText(pdf, pageNumber + 1).then((nextPagesText) => {
              pdf.getPage(pageNumber).then((page) => {
                  page.getTextContent().then((content) => {
                      const pageText = itemsToString(content.items);
                      resolve(pageText + nextPagesText);
                  });
              });
          });
      }
  });
}

</script>

<style lang="scss">
.modal-remuneration-table {
  max-height: 60vh;
  overflow-y: auto;

  &__table {
    margin-bottom: 0;

    td, th {
      vertical-align: middle !important;
      display: table-cell !important;
      padding: 0.5rem 1.5rem !important;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
  
  &:hover {
    opacity: 0.8;
  }
}
</style>
